<!--
File: Regions.vue
Description: show list of regions entered in the DB.
-->
<template>
  <md-card>
    <md-card-header class="md-card-header-icon md-card-header-green">
      <div class="md-layout">
        <div class="md-layout-item md-small-size-100 md-size-20">
          <md-field>
            <md-input type="search" class="mb-3" clearable style="width: 200px"
              :placeholder="$t('label.search_records')" v-model="searchQuery"></md-input>
          </md-field>
        </div>
        <div class="md-layout-item btn-row md-small-size-100">
          <md-button class="md-success" @click="addRegion"> {{ $t('buttons.add') }} </md-button>
        </div>
      </div>
    </md-card-header>
    <md-card-content>
      <md-table class="paginated-table table-striped table-hover" :value="queriedData" :md-sort.sync="currentSort"
        :md-sort-order.sync="currentSortOrder" :md-sort-fn="customSort">
        <md-table-row slot="md-table-row" slot-scope="{item}">
          <md-table-cell :md-label="$t('road_network.region')" md-sort-by="region_description">
            {{ item.region_description }}
          </md-table-cell>
          <md-table-cell :md-label="$t('road_network.region_key')" md-sort-by="region_key">
            {{ item.region_key }}
          </md-table-cell>
          <md-table-cell :md-label="$t('road_network.regional_office')" md-sort-by="regional_dep">
            {{ item.regional_dep }}
          </md-table-cell>
          <md-table-cell :md-label="$t('road_network.section_count')" md-sort-by="section_count" md-numeric>
            <a v-if="item.section_count > 0" href='#' @click.stop.prevent='showSections(item.region_id)'>
              {{ item.section_count }}</a>
          </md-table-cell>
          <md-table-cell :md-label="$t('tables.actions')">
            <div>
              <md-button class="md-just-icon md-default md-simple" :title="$t('buttons.edit')"
                @click.native="editRegion(item)">
                <md-icon>edit</md-icon>
              </md-button>
              <md-button v-if="amIAdmin" class="md-just-icon md-danger md-simple" :title="$t('buttons.delete')"
                @click.stop.prevent="handleDelete(item)">
                <md-icon>delete</md-icon>
              </md-button>
            </div>
          </md-table-cell>
        </md-table-row>
      </md-table>
      <div class="footer-table md-table">
        <table>
          <tfoot>
            <tr>
              <th v-for="item in footerTable" :key="item.name" class="md-table-head">
                <div class="md-table-head-container md-ripple md-disabled">
                  <div class="md-table-head-label">{{ item }}</div>
                </div>
              </th>
            </tr>
          </tfoot>
        </table>
      </div>
    </md-card-content>
    <md-card-actions md-alignment="space-between">
      <div class>
        <p class="card-category">
          {{ $t('label.showing_from_to_of_entries', { from: to > 0 ? from + 1 : 0, to: to, total }) }}
        </p>
      </div>
      <div class="md-layout-item md-small-size-100 md-size-20">
        <md-field>
          <label for="pages">{{ $t('label.per_page') }}</label>
          <md-select v-model="pagination.perPage" name="pages">
            <md-option v-for="item in pagination.perPageOptions" :key="item" :label="item" :value="item">
              {{ item }}
            </md-option>
          </md-select>
        </md-field>
      </div>
      <pagination class="pagination-no-border pagination-success" v-model="pagination.currentPage"
        :per-page="pagination.perPage" :total="total"></pagination>
    </md-card-actions>
  </md-card>
</template>
<script>
import { Pagination } from '@/components'
import Swal from 'sweetalert2'
import Fuse from 'fuse.js'

export default {
  name: 'regions-list',
  data() {
    return {
      selected_roadnetwork: null,

      pagination: {
        perPage: 20,
        currentPage: 1,
        perPageOptions: [10, 20, 50],
        total: 0
      },
      searchQuery: '',
      propsToSearch: ['region_description'],
      searchedData: [],
      currentSort: 'region_description',
      currentSortOrder: 'asc',
      fuseSearch: null,
      footerTable: []
    }
  },

  mounted() {
    this.reloadData()
  },
  components: {
    Pagination
  },
  methods: {
    reloadData() {
      //        this.searchQuery = ''
      this.$store.dispatch('LOAD_RDB_REGIONS_LIST').then(() => {
        this.fuseSearch = new Fuse(this.tableData, { keys: this.propsToSearch, threshold: 0.3 })

        this.$store.dispatch('LOAD_HISTORY').then((history) => {
          console.log("history: ", history)
          if (history.for === 'regions' && history.use) {
            this.searchQuery = history.data.searchQuery
            this.currentSort = history.data.currentSort
            this.currentSortOrder = history.data.currentSortOrder
            this.pagination.perPage = history.data.perPage
            this.$nextTick(() => {
              this.pagination.currentPage = history.data.currentPage
            });
            this.$store.dispatch('CLEAR_HISTORY')
          }
        })
      })
    },
    customSort(value) {
      return value.sort((a, b) => {
        const sortBy = this.currentSort
        const res = (!a[sortBy]) ? -1 : (!b[sortBy]) ? 1 :
          a[sortBy].toString().localeCompare(b[sortBy].toString(), undefined, { numeric: true })
        return (this.currentSortOrder === 'desc') ? -res : res
      })
    },
    save_history() {
      const hist = {
        searchQuery: this.searchQuery,
        currentSort: this.currentSort,
        currentSortOrder: this.currentSortOrder,
        perPage: this.pagination.perPage,
        currentPage: this.pagination.currentPage
      }
      //localStorage.setItem('history', btoa(unescape(encodeURIComponent(JSON.stringify(hist)))))
      this.$store.dispatch('SAVE_HISTORY', { data: hist, for: 'regions' })
    },
    editRegion(id) {
      this.save_history()
      this.$router.push(`/inventory_data/regions/upd/${id.region_id}`)
    },
    addRegion() {
      this.save_history()
      this.$router.push(`/inventory_data/regions/add`)
    },
    showSections(region_id) {
      const hist = {
        selected_region: region_id,
        perPage: 20,
        currentPage: 1
      }
      this.$store.dispatch('SAVE_HISTORY', { data: hist, for: 'sections' })
      this.$store.dispatch('SET_USE_HISTORY')
      this.$router.push('/inventory_data/sections')
    },
    handleDelete(item) {
      const alert = {
        title: this.$t('modal.are_you_sure'),
        html: this.$t('modal.you_want_delete', { name: item.region_description }) + '<br><br>' +
          this.$t('modal.the_operation_cannot_be_canceled'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.$t('modal.yes_delete'),
        cancelButtonText: this.$t('buttons.cancel'),
      }
      this.save_history()
      Swal.fire(alert).then((result) => {
        if (result.value) {
          this.$store.dispatch('DEL_RDB_REGION', item.region_id).then(
            () => {
              this.reloadData()
              Swal.fire(this.$t('modal.deleted'), this.$t('modal.you_deleted', { name: item.region_description }), 'success')
            },
            (err) => {
              Swal.fire(this.$t('messages.error'), err, 'error')
            }
          )
        }
      })
    },
  },

  computed: {
    amIAdmin() {
      return this.$store.getters.amIAdmin
    },
    regionsList() {
      return this.$store.state.Regions.list
    },
    queriedData() {
      const result = !this.searchQuery ? this.tableData : this.searchedData ? this.searchedData : []
      return result.slice(this.from, this.to)
    },
    to() {
      let highBound = this.from + this.pagination.perPage
      if (this.total < highBound) highBound = this.total
      return highBound
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1)
    },
    total() {
      return this.searchQuery ? this.searchedData.length : this.tableData.length
    },
    tableData() {
      return this.selected_roadnetwork ? this.regionsList.filter((item) => {
        return item.roadnetwork == this.selected_roadnetwork
      }) : this.regionsList
    }
  },

  watch: {
    searchQuery(value) {
      if (this.regionsList.length == 0) {
        this.searchedData = []
        return
      }
      this.searchedData = (value !== '') ? this.fuseSearch.search(this.searchQuery) : this.tableData
    }
  }
}
</script>
<style lang="scss" scoped>
.cell-actions {
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  .md-button {
    margin: 3px 0;
    min-width: 100px;
  }
}

.md-table-head-label {
  padding-right: 0;
}

.btn-row {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}

.md-card {
  margin: 0px 0;
}

.md-card .md-card-actions {
  border: 0;
  margin-left: 20px;
  margin-right: 20px;
}

.md-button+.md-button {
  margin-left: 10px;
}
</style>